<template>
	<div id="seedList">
		<page-seed-list :is-shop="1"></page-seed-list>
	</div>
</template>

<script>
	import pageSeedList from '@/components/layout/land/page-seed-list.vue'
	export default{
		components:{
			pageSeedList
		}
	}
</script>

<style lang="scss">
	
</style>
